.App {
  width: 95vw;
  height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.Footer{
  margin-top: 5%;
  width: 95vw;
  font-size: 0.6rem;
  color: grey;
}

.Footer {
  visibility: hidden;
  animation: superman-fly-right ease-in-out 0.5s forwards;
  animation-delay: 4s;
}

.Footer a{
  color: grey;
}

@keyframes superman-fly-right {
  from {
    visibility: hidden;
    opacity: 0;
    transform: translateY(10vw);
  }

  to {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}

.typingbox {
  width: 100%;
  height: 100px;
  margin-top: 1%;
  text-align: center;
  -webkit-display: flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.typinganimation {
  font-weight: 1000;
  font-size: 2rem;
  line-height: 100px;
  background: linear-gradient(270deg, rgba(198, 141, 255, 0.9) 8.92%, #5685ff 46.17%, #48d6ff 92.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  visibility: hidden;
  text-fill-color: transparent;
  white-space: nowrap;
  animation: animateText 2.5s steps(8);
  animation-fill-mode: forwards;
  -webkit-animation: animateText 2.5s steps(8);
  -webkit-animation-fill-mode: forwards;
  animation-delay: 1s;
}

@-webkit-keyframes animateText {
  0% {
    visibility: hidden;
    width: 3em;
  }

  35% {
    visibility: visible;
    width: 3em;
  }

  50% {
    visibility: visible;
    width: 5em;
  }

  60% {
    visibility: visible;
    width: 5em;
  }

  75% {
    visibility: visible;
    width: 7em;
  }

  85% {
    visibility: visible;
    width: 7em;
  }

  100% {
    visibility: visible;
    width: 11em;
  }
}

@keyframes animateText {
  0% {
    visibility: hidden;
    width: 3em;
  }

  35% {
    visibility: visible;
    width: 3em;
  }

  50% {
    visibility: visible;
    width: 5em;
  }

  60% {
    visibility: visible;
    width: 5em;
  }

  75% {
    visibility: visible;
    width: 7em;
  }

  85% {
    visibility: visible;
    width: 7em;
  }

  100% {
    visibility: visible;
    width: 11em;
  }
}

.tabview {
  width: 100%;
  height: 0px;
}
.TitleBox {
  width: auto;
  display: inline-block !important;
  display: inline;
}
.site-header {
  width: 95vw;
  height: 75px;
  background: none;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: relative;
  z-index: 9999;
  top: 0;
}
.site-header:hover {
  background: #fff;
}

.site-branding {
  position: relative;
  display: inline-flex;
  width: 100%;
  height: 75px;
  line-height: 75px;
  margin-left: 13px;
}
.logolink.moe-mashiro .sakurasono {
  font-size: 25px;
  border-radius: 9px;
  padding-bottom: 2px;
  padding-top: 5px;
}
.site-branding {
  animation: sitetop 3s;
}
.logolink.moe-mashiro a {
  color: #464646;
  float: left;
  font-size: 28px;
  font-weight: 800;
  height: 56px;
  line-height: 56px;
  padding-left: 6px;
  padding-right: 15px;
  padding-top: 11px;
  text-decoration-line: none;
}
.logolink .sakurasono {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  color: #464646;
  height: auto;
  line-height: 25px;
  margin-right: 0;
  padding-bottom: 0;
  padding-top: 1px;
  text-size-adjust: 100%;
  width: auto;
}
.moe-mashiro {
  font-family: "Moe-Mashiro", "Merriweather Sans", Helvetica, Tahoma, Arial,
    "PingFang SC", "Hiragino Sans GB", "Microsoft Yahei", "WenQuanYi Micro Hei",
    sans-serif;
}

.site-branding:hover .sakurasono {
  background: #fe9600;
  color: white;
}
.site-branding:hover .shironeko {
  color: #fe9600;
}
.site-branding:hover .menu_item {
  color: #fe9600;
}

@keyframes sitetop {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}